import React from 'react';

const IconLogo = () => (
  <svg id="logo" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="44 30 120 130" >
  <path stroke="currentColor" stroke-width="5" d="M53.6,-32.9C63.6,-13.6,61.9,10.6,51,27.6C40.1,44.7,20.1,54.6,0.6,54.3C-18.9,53.9,-37.9,43.4,-46.5,27.7C-55,12,-53.2,-8.9,-43.8,-27.9C-34.3,-47,-17.2,-64.2,2.3,-65.6C21.8,-66.9,43.5,-52.3,53.6,-32.9Z" transform="translate(100 100)" />
  <g transform="scale(1.6 1.6)">
    <path
          d="m 38.290751,64.65815 c 12.803037,0 21.777715,-8.421431 21.777715,-20.427656 0,-10.697493 -7.593975,-17.070467 -20.271478,-17.070467 0,0 -7.091881,0.284507 -9.978818,0.284507 -2.447612,0 -8.033256,-0.170704 -8.033256,-0.170704 l -0.502071,3.527897 0.376559,0.512113 c 0,0 1.255189,-0.113802 2.071076,-0.113802 1.568981,0 2.008307,0.625916 2.008307,3.072682 l -0.06277,21.565688 c 0,3.18649 -0.941398,4.210717 -4.518711,4.836633 l -0.439303,3.869305 c 0,0 4.769734,-0.569014 8.096023,-0.569014 3.577292,0 9.476727,0.682818 9.476727,0.682818 z M 36.470724,32.110463 c 9.288468,0 13.744411,4.210716 13.744411,13.883979 0,9.104245 -3.891105,13.713274 -11.171265,13.713274 -4.142151,0 -5.209061,-0.796622 -5.209061,-4.040011 0,-5.064238 0.313791,-12.632145 0.815863,-22.191605 0.06277,-1.081131 0.439326,-1.365637 1.820052,-1.365637 z"
          transform="translate(25 12)" 
          fill="currentColor"
        />
      </g>
</svg>
  
);

export default IconLogo;
